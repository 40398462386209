<template>
	<div>
		<div class="main-content">
			<div class="header bg-white border-bottom">
				<div class="container-fluid">
					<div class="header-body border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Staff Overview</h6>
								<h1 class="header-title">
									{{ `${currentUser.fname} ${currentUser.lname}` }}
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container-fluid">
				<admin-profile
					:user="currentUser"
					entity-type="staff"
					@profile-updated="profileUpdated"
					></admin-profile>
			</div>
		</div>
	</div>
</template>

<script>
import AdminProfile from '@/components/modules/admins/AdminProfile.vue'

export default {
  components: {
    AdminProfile
  },
  computed: {
    currentUser () {
      return this.$store.getters.currentUser
    }
  },
  methods: {
	  profileUpdated(value){
		  this.$store.dispatch('setCurrentUser', value)

    }
  }
}
</script>
